// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios'; 
import { BASE_URL } from '../Environment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
function Footer() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [email, setEmail] = useState(''); 
  const [error, setError] = useState('');  
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post(`${BASE_URL}/news-letter`, { email }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      toast.success(res.data.message, { autoClose: 3000 });
      e.target.reset();     
      setShow(false); 
    }).catch((res) => {
      setError(res.response.data); 
    })  
  }
  return (
    <>
      <ToastContainer />   
      <Modal show={show} onHide={handleClose}  centered> 
        <Modal.Body>
          <h4 className='newsletter-title'>E-newsletter</h4>
          <form className='p-4' onSubmit={ handleSubmit }>
            <label for="email">Email ID</label>
            <input type="email" id="email" name="email" onChange={ e => setEmail(e.target.value) } className='form-control' placeholder='Enter your email id' />
            { error ? <small className='input-required'>{ error.data.email[0] }</small> : null }
            <div className='text-center mt-4'> 
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>      
              &nbsp;&nbsp;
              <Button variant="primary" type='submit'>
                Subscribe
              </Button>  
            </div>    
          </form>    
        </Modal.Body>
      </Modal>
      <footer className='footer bg-dark text-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <h5>Join Our Mailing List</h5>
              <p>Get regular updates about upcoming events, trip planning advice and compelling stories.</p>
              <button className='btn btn-primary' onClick={handleShow}><i className='fas fa-envelope me-2'></i>Sign up for our E-newsletter</button>
              <div className='d-flex mt-5'>
                <a href='https://www.facebook.com/ecstaticexplorers' className='me-2' target='_blank'><img src='/facebook.svg' alt='Facebook-image' /></a>
                <a href='https://www.instagram.com/ecstaticexplorers' target='_blank'><img src='/instagram-logo.svg' alt='instagram' /></a> 
              </div>  
            </div>
            <div className='col-md-2'>
              <h5>Quick Links</h5>
              <ul className='list-unstyled'>
                <li><Link to='/home' className='text-light'>Home</Link></li>
                <li><Link to='/about-us' className='text-light'>About Us</Link></li>
                <li><Link to='/contact-us' className='text-light'>Contact Us</Link></li>
              </ul>
            </div>
            <div className='col-md-2'>
              <h5>Other Popular Tours</h5>
              <ul className='list-unstyled'>
                <li>North Bengal Offbeat</li>
                <li>Himachal</li>
                <li>Kashmir</li>
                <li>Leh Ladakh</li>
                <li>Sikkim</li>
                <li>Weekend Gateway</li>
              </ul>
            </div>
          </div>
          <div className='row bottom-footer'>
            <div className='col-md-4'>
              <div className='d-flex'>
                <Link to="/privacy-policy" className='me-2 fs-6 text-white text-decoration-none'>Privacy Policy</Link>
                <div className=''>|</div>
                <Link to="/terms-and-conditions" className='ms-2 text-white text-decoration-none'>Terms & Conditions</Link>
              </div> 
            </div>
            <div className='col-md-6'>
              <div className='text-center '>
                <p className='mb-0 fs-6'>&copy; 2024 Ecstatic Explorers – All Rights Reserved</p>
              </div>
            </div>
            <div className='col-md-2'>
              <div className='d-flex justify-content-end'>
                <a href='tel:+1234567890' className='me-2'><img src='/call.svg' alt='Call' /></a>
                <a href='https://wa.me/+1234567890?text=Hello' target='_blank'><img src='/wp.svg' alt='Wp' /></a>
              </div> 
            </div>
          </div>

        </div>
      </footer>
    </>
  );
}

export default Footer;

