import { React, useState, useEffect } from 'react'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import { Link, useParams } from 'react-router-dom';
import 'swiper/css/navigation';
import 'swiper/css/pagination'; 
import { Accordion } from 'react-bootstrap'; 
import { Range } from 'react-range';   
import Form from 'react-bootstrap/Form';
import axios from 'axios'; 
import { BASE_URL } from '../Environment';
  
function Package() {
  const { id } = useParams();    
  const [durationRange, setDurationRange] = useState([3, 4]);
  const [budgetRange, setBudgetRange] = useState([5000, 20000]);
  const [selectedBudgetRanges, setSelectedBudgetRanges] = useState({
    '25000': false,
    '35000': false,
    '40000': false,
    'above40000': false,
  }); 
  const DURATION_MIN = 3;
  const DURATION_MAX = 10;
  const BUDGET_MIN = 5000;
  const BUDGET_MAX = 20000;
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedBudgetRanges((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const [city, setCity] = useState([]);
  const [theme, setTheme] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const checkboxData = [  
    { id: 'budget-35000', name: '35000', label: 'Family Package', count: 139 },
    { id: 'budget-350001', name: '350001', label: 'Adventure Package', count: 139 },
    { id: 'budget-350002', name: '350002', label: 'Wildlife', count: 139 },
    { id: 'budget-350003', name: '350003', label: 'Food', count: 139 },
    { id: 'budget-350004', name: '350004', label: 'Beach Package', count: 139 },
    { id: 'budget-350005', name: '350005', label: 'Mountain Package', count: 139 },
    { id: 'budget-350006', name: '350006', label: 'Cultural Tour', count: 139 },
    { id: 'budget-350007', name: '350007', label: 'Luxury', count: 139 },
    { id: 'budget-350008', name: '350008', label: 'Budget Tour', count: 139 },
    { id: 'budget-350009', name: '350009', label: 'Honeymoon', count: 139 }
  ]; 
  const [showMoreCategory, setShowMoreCategory] = useState(false); 
  const [showMoreCities, setShowMoreCities] = useState(false);
  const [showMoreThemes, setShowMoreThemes] = useState(false);
  const cities = [
    { name: 'Mumbai', count: 39 },
    { name: 'Jaisalmer', count: 24 },
    { name: 'Manali', count: 55 },
    { name: 'Udaipur', count: 30 },
    { name: 'Delhi', count: 24 },
    { name: 'Jaipur', count: 20 },
    { name: 'Kolkata', count: 15 },
    { name: 'Bangalore', count: 18 },
  ];
  const themes = [
    { name: 'Culture', count: 14 },
    { name: 'Wildlife', count: 6 },
    { name: 'Honeymoon', count: 8 },
    { name: 'Pilgrimage', count: 5 },
    { name: 'Adventure', count: 9 },
    { name: 'Beach', count: 12 },
  ];
  const initialVisibleItems = 6;

  const base_url = window.location.origin;
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [isLoading, setIsLoading] = useState(false);  
  const [destination, setDestination] = useState([]);
  const [category, setCategory] = useState([]);
  const [packages, setPackages] = useState([]); 
  const [all_packages, setAllPackages] = useState([]); 
  useEffect(() => {  
    setIsLoading(true); 
    axios.get(`${BASE_URL}/cities`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setCity(res.data.data);  
    }).catch((res) => { 
      console.log(res); 
    });
    axios.get(`${BASE_URL}/themes`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setTheme(res.data.data);  
    }).catch((res) => { 
      console.log(res); 
    }); 
    axios.get(`${BASE_URL}/top-destination`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setDestination(res.data.data); 
    }).catch((res) => { 
      console.log(res); 
    });   
    axios.get(`${BASE_URL}/categories`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setCategory(res.data.data); 
    }).catch((res) => { 
      console.log(res); 
    }); 
    axios.get(`${BASE_URL}/pakages-with-state/`+id, {
      headers: {  
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setPackages(res.data.data);  
      setIsLoading(false); 
    }).catch((res) => { 
      console.log(res); 
    });   
    axios.get(`${BASE_URL}/pakages`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
      }).then((res) => {
        setAllPackages(res.data.data);   
      }).catch((res) => {  
        console.log(res); 
    }); 
  }, []);  
  const onGetPackage = param => {
    setIsLoading(true); 
    if(param == '') {
      axios.get(`${BASE_URL}/pakages`, {
        headers: { 
          'Content-Type': 'application/json'
        } 
      }).then((res) => {
        setPackages(res.data.data); 
        setIsLoading(false); 
      }).catch((res) => { 
        console.log(res); 
      });  
    } else {
      axios.get(`${BASE_URL}/pakages-with-category/`+param, {
        headers: { 
          'Content-Type': 'application/json'
        } 
      }).then((res) => {
        setPackages(res.data.data); 
        setIsLoading(false); 
      }).catch((res) => { 
        console.log(res); 
      });  
    } 
  };    
  const onChangePrice = (data) => {   
    setIsLoading(true);
    axios.get(`${BASE_URL}/pakages-with-price/`+data, {
      headers: {   
        'Content-Type': 'application/json'
      }      
    }).then((res) => {   
      setPackages(res.data.data);   
      setIsLoading(false); 
    }).catch((res) => {
      console.log(res); 
    });    
  };  
  const onChangeCategory = (id) => {
    setIsLoading(true);
    axios.get(`${BASE_URL}/pakages-with-category/`+id, {
      headers: {   
        'Content-Type': 'application/json'
      }     
    }).then((res) => {   
      setPackages(res.data.data);   
      setIsLoading(false); 
    }).catch((res) => {
      console.log(res); 
    });    
  };   
  const onChangeCity = (id) => {
    setIsLoading(true);
    axios.get(`${BASE_URL}/pakages-with-city/`+id, {
      headers: {   
        'Content-Type': 'application/json'
      }     
    }).then((res) => {   
      setPackages(res.data.data);   
      setIsLoading(false); 
    }).catch((res) => {
      console.log(res); 
    });    
  };  
  const onChangeTheme = (id) => {
    setIsLoading(true);
    axios.get(`${BASE_URL}/pakages-with-theme/`+id, {
      headers: {    
        'Content-Type': 'application/json'
      }     
    }).then((res) => {   
      setPackages(res.data.data);   
      setIsLoading(false); 
    }).catch((res) => {
      console.log(res); 
    });    
  };   
  function onSortPackage(event) {
    const copyArray = [...packages]; 
    copyArray.sort((a, b) => {
      if(event.target.value == 'low-to-high') {
        return a.lowest_price - b.lowest_price; 
      } else if(event.target.value == 'high-to-low') {
        return b.lowest_price - a.lowest_price; 
      } 
    }); 
    setPackages(copyArray); 
  }   
  return (
    <div className='app'>
      <div className='main-content'>
        <section className='banner-inner-section'>
          <div className='container'>
            <div className='row'>
              <div className='banner-content'>
                <h3>One Stop Solution for All Your Travel Needs</h3>
                <h4>Ecstatic Explorers - Dream, Explore, Rejuvenate</h4>
                <div className='feature-lists'>
                  <div className='feature-item'>
                    <div className='icon'>
                      <img src='/globe.svg' alt='Location-img' />
                    </div>
                    <div className='text-part'>
                      <h5>27</h5>
                      <p>Destinations</p>
                    </div>
                  </div>
                  <div className='feature-item'>
                    <div className='icon'>
                      <img src='/hotel.svg' alt='Location-img' />
                    </div>
                    <div className='text-part'>
                      <h5>111</h5>
                      <p>Hotels</p>
                    </div>
                  </div>
                  <div className='feature-item'>
                    <div className='icon'>
                      <img src='/people.svg' alt='Location-img' />
                    </div>
                    <div className='text-part'>
                      <h5>1328</h5>
                      <p>Tourists</p>
                    </div>
                  </div>
                  <div className='feature-item'>
                    <div className='icon'>
                      <img src='/location.svg' alt='Location-img' />
                    </div>
                    <div className='text-part'>
                      <h5>414</h5>
                      <p>Tours</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='container pt-4'>
          <div className='row justify-content-center'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'><Link to='/home'><i className='fas fa-home'></i></Link></li> 
                <span className='arrow'><i className='fas fa-chevron-right'></i></span> 
                <li className='breadcrumb-item ' aria-current='page'>Packages</li>
              </ol> 
            </nav>
          </div>
        </div> 
        <section className='category-section'>
          <div className='circle-container'>
            <div className='rotating-text'>
              <svg className='circle-svg' viewBox='0 0 100 100'>
                <path
                  id='circlePath'
                  d='M 50, 50 m -35, 0 a 35,35 0 1,1 70,0 a 35,35 0 1,1 -70,0'
                  fill='transparent'
                ></path>
                <text fill='#000'>
                  <textPath href='#circlePath' startOffset='0%'>
                    GALLERY • GALLERY • GALLERY • GALLERY •
                  </textPath>
                </text>
              </svg>
            </div>

            <div className='center-button'>
              <button className='view-more-btn'>View More Images</button>
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='category-title'>Top Destinations</h2>
                <Swiper
                  modules={[Navigation, Autoplay]}
                  spaceBetween={0}
                  slidesPerView={6}
                  navigation={{ clickable: true }}
                  loop={true}
                  breakpoints={{
                    // When the window width is >= 320px
                    320: {
                      slidesPerView: 2,
                    },
                    // When the window width is >= 480px
                    480: {
                      slidesPerView: 2,
                    },
                    // When the window width is >= 640px
                    640: {
                      slidesPerView: 3,
                    },
                    // When the window width is >= 768px
                    768: {
                      slidesPerView: 4,
                    },
                    // When the window width is >= 1024px
                    1024: {
                      slidesPerView: 5,
                    },
                    1366: {
                      slidesPerView: 5,
                    },
                    1600: {
                      slidesPerView: 6,
                    },
                  }}
                >
                  { destination.map((data) => (
                    <SwiperSlide>
                      <div className='category-item on-click-data' onClick={ () => onChangeCity(data.id) }> 
                        <div className='category-image'>
                          <img src={ data.file_path } alt='Category-img' />
                        </div>
                        <div className='text'>{ data.name }</div>
                      </div> 
                    </SwiperSlide>    
                  ))} 
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section className='lists-section'>
          <div className='green-strip'></div>
          <div className='container'>
            <div className='row'>
              <div className='filter-inner'>
                <div className='sidebar-wrapper'>
                  {/* Button to toggle sidebar */}
                  <button className='toggle-button' onClick={toggleSidebar}>
                  <i class="fas fa-filter me-2"></i>{isOpen ? 'Close Filters' : 'Open Filters'}
                  </button>

                  {/* Sidebar container */}
                  <div className={`filter-sidebar ${isOpen ? 'open' : ''}`}>
                    {/* Close button inside the sidebar */}
                    <div className='close-action'>
                      <button className='close-button' onClick={toggleSidebar}>✖</button>
                    </div>

                    <h5>Filters</h5>
                    <div className='filter-apply'>
                      <Accordion defaultActiveKey={['0']} alwaysOpen>
                        {/* Duration Accordion */}
                        <Accordion.Item eventKey='0'>
                          <Accordion.Header>Duration (In Nights)</Accordion.Header>
                          <Accordion.Body>

                            <Range
                              step={1}
                              min={DURATION_MIN}
                              max={DURATION_MAX}
                              values={durationRange}
                              onChange={(values) => setDurationRange(values)}
                              renderTrack={({ props, children }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: '6px',
                                    background: '#28a745',
                                    margin: '10px 0',
                                  }}
                                >
                                  {children}
                                </div>
                              )}
                              renderThumb={({ props }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: '24px',
                                    width: '24px',
                                    backgroundColor: '#fff',
                                    boxShadow: '0px 4px 38px 18px rgba(0, 0, 0, 0.05)',
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                            />
                            <div className='d-flex justify-content-between'>
                              <span className='price'>{durationRange[0]}N</span>
                              <span className='price'>{durationRange[1]}N</span>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>

                        {/* Budget Accordion */}
                        <Accordion.Item eventKey='1'>
                          <Accordion.Header>Budget (Per Person)</Accordion.Header>
                          <Accordion.Body>

                            <Range
                              step={1000}
                              min={BUDGET_MIN}
                              max={BUDGET_MAX}
                              values={budgetRange}
                              onChange={(values) => setBudgetRange(values)}
                              renderTrack={({ props, children }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: '6px',
                                    background: '#28a745',
                                    margin: '10px 0',
                                  }}
                                >
                                  {children}
                                </div>
                              )}
                              renderThumb={({ props }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: '24px',
                                    width: '24px',
                                    backgroundColor: '#fff',
                                    boxShadow: '0px 4px 38px 18px rgba(0, 0, 0, 0.05)',
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                            />
                            <div className='d-flex justify-content-between'>
                              <span className='price'>₹{budgetRange[0]}</span>
                              <span className='price'>₹{budgetRange[1]}</span>
                            </div>

                            {/* Budget Checkboxes */}
                            <div className='mt-3'>
                              <div className='custom-checkbox'>
                                <input type='radio' name='budget' id='budget-1' onChange={ () => onChangePrice('10000-20000') } />  
                                <label htmlFor='budget-1'>
                                  <span className='price'>₹10,000 - ₹20,000</span>
                                  {/* <span className='text-muted'>(139)</span> */}
                                </label>
                              </div>

                              <div className='custom-checkbox'>
                              <input type='radio' name='budget' id='budget-2' onChange={ () => onChangePrice('20000-30000') } />
                                <label htmlFor='budget-2'>
                                  <span className='price'>₹20,000 - ₹30,000</span>
                                  {/* <span className='text-muted'>(139)</span> */}
                                </label>
                              </div>

                              <div className='custom-checkbox'>
                              <input type='radio' name='budget' id='budget-3' onChange={ () => onChangePrice('30000-40000') } />
                                <label htmlFor='budget-3'>
                                  <span className='price'>₹30,000 - ₹40,000</span>
                                  {/* <span className='text-muted'>(139)</span> */}
                                </label>
                              </div>

                              <div className='custom-checkbox'>
                                <input type='radio' name='budget' id='budget-4' onChange={ () => onChangePrice('40000-50000') } />
                                <label htmlFor='budget-4'>
                                  <span className='price'>₹40,000 - ₹50,000</span>
                                  {/* <span className='text-muted'>(139)</span> */}
                                </label>   
                              </div> 
                            </div> 
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='2'>
                          <Accordion.Header>Package Category</Accordion.Header>
                          <Accordion.Body>
                            <div className=''>
                              {category.slice(0, showMoreCategory ? category.length : initialVisibleItems).map((category, index) => (
                                <div className='custom-checkbox' key={index}>
                                  <input type='radio' name='category' id={category.name} onChange={ () => onChangeCategory(category.id) } />
                                  <label htmlFor={category.name}> 
                                    <span>{category.name}</span>
                                    <span className='text-muted'>({category.pakages?.length})</span>
                                  </label>        
                                </div>      
                              ))}  
                              <div className='show-more' onClick={() => setShowMoreCategory(!showMoreCategory)}>
                                {showMoreCategory ? 'Show Less' : 'Show More'}
                              </div>    
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='3'>
                          <Accordion.Header>Cities</Accordion.Header>
                          <Accordion.Body>
                            <div className=''>
                              {/* <input type="text" placeholder="Search..." className='search-bar' /> */}
                              {city.slice(0, showMoreCities ? city.length : initialVisibleItems).map((city, index) => (
                                <div className='custom-checkbox' key={index}>
                                  <input type='radio' name='city' id={city.name} onChange={ () => onChangeCity(city.id) } />
                                  <label htmlFor={city.name}> 
                                    <span>{city.name}</span>
                                    <span className='text-muted'>({city.package?.length})</span>
                                  </label> 
                                </div>       
                              ))}    
                              <div className='show-more' onClick={() => setShowMoreCities(!showMoreCities)}>
                                {showMoreCities ? 'Show Less' : 'Show More'}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='4'>
                          <Accordion.Header>Themes</Accordion.Header>
                          <Accordion.Body>
                            <div className=''>
                              {theme.slice(0, showMoreThemes ? theme.length : initialVisibleItems).map((theme, index) => (
                                <div className='custom-checkbox' key={index}>
                                  <input type='radio' name='theme' id={theme.name} onChange={ () => onChangeTheme(theme.id) } />
                                  <label htmlFor={theme.name}> 
                                    <span>{theme.name}</span>
                                    <span className='text-muted'>({theme.package?.length})</span>
                                  </label>      
                                </div>     
                              ))}  
                              <div className='show-more' onClick={() => setShowMoreThemes(!showMoreThemes)}>
                                {showMoreThemes ? 'Show Less' : 'Show More'}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div >
                  </div> 
                </div>

                <div className='filter-body'>
                  <div className='lists'>
                    <Swiper
                      modules={[Navigation, Autoplay]}
                      spaceBetween={0}
                      slidesPerView={5}
                      navigation={{ clickable: true }}
                      loop={true}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        480: {
                          slidesPerView: 1,
                        },
                        640: {
                          slidesPerView: 2,
                        },
                        768: {
                          slidesPerView: 2,
                        },
                        1024: {
                          slidesPerView: 3,
                        },
                        1366: {
                          slidesPerView: 4,
                        },
                        1600: {
                          slidesPerView: 5,
                        },
                      }}
                    >
                      <SwiperSlide>
                        <div className='list-item-name' onClick={ () => onGetPackage('') }>All Packages ({ all_packages.length })</div>
                      </SwiperSlide>    
                      { category.map((data) => (   
                        <SwiperSlide>
                          <div className='list-item-name' onClick={ () => onGetPackage(data.id) }>{ data.name } ({ data.pakages.length })</div>
                        </SwiperSlide>   
                      ))}   
                    </Swiper>
                  </div>
                  <div className='select-part'>
                    <span className='text-part'>Sort By:</span>
                    <Form.Select aria-label='Default select example' onChange={ onSortPackage }>
                      <option value='default-sorting'>Default Sorting</option> 
                      <option value='low-to-high'>Low to High</option>
                      <option value='high-to-low'>High to Low</option> 
                    </Form.Select>    
                  </div> 
                  { isLoading ? <p className='is-loading'>Loading...</p> : null } 
                  <div className='panel-part'> 
                    { packages.map((data) => (
                      <div className='list-card'>
                        <div className='image-part'>
                          <img src={ data.file_path } alt='Category-img' />
                        </div>
                        <div className='content-part'>
                          <Link to={ '/package-details/' + data.id } className='text-decoration-none'> 
                            <div className='name'>{ data.title }</div> 
                            <div className='tag-lists'> 
                              { data.pakage_cities.map((data) => (
                                <div className='tag'>{ data.duration }D { data.city.name }</div>
                              ))} 
                            </div>
                          </Link>
                          <ul className='feature-lists'>
                            { data.pakage_tags.map((data) => (
                              <li className='feature-item'>
                                <span>{ data.tag.name }</span>
                              </li>
                            ))}  
                          </ul> 
                          <div className='part-lists'>
                            { data.pakage_features.map((data) => (
                              <div className='part-item'>
                                <div className='icon'>
                                  <img src='/check.svg' alt='Check-img' />
                                </div>
                                <div className='text'>{ data.name }</div>
                              </div>
                            ))} 
                          </div> 
                          <div className='card-part'>
                            <div className='price-part'>
                              <h5>Includes extra <strong>₹{ data.total_price - data.lowest_price }</strong></h5>
                              <p>discount for packages with flights</p>
                            </div>
                            <div className='total-pricepart'>
                              <h5><strong><span>₹{ data.lowest_price }</span></strong> /Person</h5>
                              <p>Total Price ₹{ data.total_price }</p>
                            </div> 
                          </div>
                          <div className='other-part'>
                            <a href={ 'whatsapp://send?text=' + base_url + '/package-details/' + data.id } className='wp-button text-decoration-none' target='_blank'>
                              <div className='img-part'>       
                                <img src='/wp.svg' alt='wp-img' />
                              </div>
                              <div className='text'>Share</div>
                            </a>
                            <div className='promo-text'>
                              <div className='icon'>
                                <img src='/promo.svg' alt='Promo-img' />
                              </div>
                              <div className='text'>Promo Icon Extra <span><strong>₹4,892</strong></span> off.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))} 
                    <div className='view-part'>
                      { packages.length > 0 ?
                        <button type='button' className='btn-view'>View More</button>
                      :
                        <h5 className='text-danger fw-bold'>Package Not Found!</h5>
                      }  
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Package; 
   