import { React, useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Accordion from 'react-bootstrap/Accordion'; 
import Banner from '../component/banner';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios'; 
import { BASE_URL, IMG_BASE_URL } from '../Environment';
   
function BlogDetails() {
  const current_url = window.location.href; 
  const [isLoading, setIsLoading] = useState(false);  
  const { id } = useParams();  
  const [blog_details, setBlogDetails] = useState([]);  
  const [blog, setBlog] = useState([]);
  useEffect(() => {  
    setIsLoading(true);
    axios.get(`${BASE_URL}/blog/`+id, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {   
      setBlogDetails(res.data.data);
      setIsLoading(false); 
    }).catch((res) => {
      console.log(res); 
    });   
    axios.get(`${BASE_URL}/blogs`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setBlog(res.data.data); 
    }).catch((res) => {
      console.log(res); 
    });        
  }, []); 
  function onHandlePrint() {
    window.print()
  } 
  return (
    <div className='app'>
      <div className='main-content'>
        <Banner />
        <div className='container pt-4'>
          <div className='row justify-content-center'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'><Link to='/home'><i className='fas fa-home'></i></Link></li>
                <span className='arrow'><i className='fas fa-chevron-right'></i></span>
                <li className='breadcrumb-item active'><Link to='/blog'>Blog</Link></li>
                <span className='arrow'><i className='fas fa-chevron-right'></i></span>
                <li className='breadcrumb-item ' aria-current='page'>Blog Details</li>
              </ol> 
            </nav>
          </div> 
        </div> 
        <section className='blog-details-section'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-1 d-flex align-items-center'>
                <div className='side-social-panel'>
                  <div className='social-part'>
                    <div className='social-icons'>
                      <div className='icon'>
                        <div className='img-part'>
                          <img src='/share.svg' alt='Share' />
                        </div>
                        <div className='text'>Share</div>
                      </div>
                      <a href={ 'https://www.facebook.com/sharer.php?u=' + current_url } className='icon' target='_blank'>
                        <div className='img-part'>
                          <img src='/facebook.svg' alt='Fb' />
                        </div>
                      </a>
                      <a href={ 'https://www.instagram.com/?url=' + current_url } className='icon' target='_blank'>
                        <div className='img-part'>  
                          <img src='/insta.svg' alt='Insta' />
                        </div>
                      </a>
                      <a href={ 'whatsapp://send?text=' + current_url } className='icon' target='_blank'>
                        <div className='img-part'>
                          <img src='/wp.svg' alt='Wp' />
                        </div>
                      </a>
                      <a href={ 'mailto:?subject=Ecstatic Explorers&amp;body=Check out this site ' + current_url } className='icon'>
                        <div className='img-part'>  
                          <img src='/env.svg' alt='Envelop' />
                        </div>
                      </a>
                      <a href='#' className='icon' onClick={ onHandlePrint }>
                        <div className='img-part'>
                          <img src='/print.svg' alt='Print' />
                        </div>
                      </a>
                    </div>  
                  </div>
                </div>
              </div>
              <div className='col-md-8'>
                <div className='blog-details'>
                  { isLoading ? <p className='is-loading'>Loading...</p> : null } 
                  <p>{ blog_details.text }</p>
                  <h5>{ blog_details.title }</h5>
                  <div className='info-update'>
                    <div className='left-part'>
                      <div className='data'>By: <span>{ blog_details.user?.first_name } { blog_details.user?.last_name }</span></div>
                      <div className='data'>Last updated: <span>{ new Date().toLocaleDateString('en-US', blog_details.updated_at) }</span></div>
                    </div>   
                    <div className='social-part'>  
                      <div className='social-icons'>
                        <div className='icon'>  
                          <div className='img-part'>
                            <img src='/share.svg' alt='Share' />
                          </div>
                          <div className='text'>Share</div>
                        </div>
                        <a href={ 'https://www.facebook.com/sharer.php?u=' + current_url } className='icon' target='_blank'>
                          <div className='img-part'>
                            <img src='/facebook.svg' alt='Fb' />
                          </div>
                        </a>
                        <a href={ 'https://www.instagram.com/?url=' + current_url } className='icon' target='_blank'>
                          <div className='img-part'>
                            <img src='/insta.svg' alt='Insta' />
                          </div>
                        </a>
                        <a href={ 'whatsapp://send?text=' + current_url } className='icon' target='_blank'>
                          <div className='img-part'>
                            <img src='/wp.svg' alt='Wp' />
                          </div>
                        </a> 
                        <a href={ 'mailto:?subject=Ecstatic Explorers&amp;body=Check out this site ' + current_url } className='icon'>
                          <div className='img-part'>
                            <img src='/env.svg' alt='Envelop' />
                          </div>
                        </a>
                        <a href='#' className='icon' onClick={ onHandlePrint }> 
                          <div className='img-part'> 
                            <img src='/print.svg' alt='Print' />
                          </div>
                        </a> 
                      </div>
                      <div className='read-time'>
                        <span>22 Min Read</span>
                      </div>
                    </div>
                  </div>
                  <div className='img-part-blog'>
                    <img src={ IMG_BASE_URL + blog_details.file_path } alt='Book' />
                  </div>  
                  <div className='author-part'>
                    <span className='line'></span>
                    <span className='caption'>{ blog_details.text }</span>
                  </div> 
                  <div className='des' dangerouslySetInnerHTML={{ __html: blog_details.description }}></div> 
                  <div className='copyright-part'> 
                    <div className='des'><span className='text-muted'>Written by:</span> <strong>{ blog_details.user?.first_name } { blog_details.user?.last_name }</strong> <br />
                      <span className='text-muted'>Translated by:</span> <strong>{ blog_details.user?.first_name } { blog_details.user?.last_name }</strong> <br />
                      <span className='text-muted'> Check out other blogs:</span> <br />
                      { blog.map((data) => (
                        <>
                          <span className='text-black'>{ data.title }</span> <br />   
                        </> 
                      ))}  
                    </div> 
                  </div>
                  <div className='social-share'>
                    <h6>Find Us on Socials</h6>
                    <div className='icons'>
                      <a href='https://www.facebook.com/ecstaticexplorers' className='icon' target='_blank'>
                        <img src='/facebook.svg' alt='Fb' />
                      </a>
                      <a href='https://www.youtube.com' className='icon' target='_blank'>
                        <img src='/y-c.svg' alt='Youtube' />
                      </a>
                      <a href='https://www.instagram.com/ecstaticexplorers' className='icon' target='_blank'>
                        <img src='/insta.svg' alt='Insta' /> 
                      </a> 
                    </div>
                  </div>
                  <div className='tag-part'>
                    <div className='tag'>
                      <div className='icon'>
                        <img src='/tag-b.svg' alt='tag' />
                      </div>
                      <div className='text-tag'><span className='text'>TAGGED:</span> { blog_details.blog_tags?.map((data) => ( <span>{ data.tag.name },</span> ))}</div>
                    </div>  
                  </div>
                  <div className='other-share'>
                    <div className='share-art'>
                      <div className='share'>
                        <div className='share-icon'>
                          <img src='/share.svg' alt='Share' />
                        </div>
                        <div className='text'>Share this Article</div>
                      </div>
                      <div className='icons-part'>
                        <a href={ 'https://www.facebook.com/sharer.php?u=' + current_url } className='icon' target='_blank'>
                          <img src='/facebook.svg' alt='Fb' />
                        </a>
                        <a href={ 'https://www.instagram.com/?url=' + current_url } className='icon' target='_blank'>
                          <img src='/insta.svg' alt='Insta' />
                        </a>
                        <a href={ 'whatsapp://send?text=' + current_url } className='icon' target='_blank'>
                          <img src='/wp.svg' alt='Wp' />
                        </a> 
                        <a href={ 'mailto:?subject=Ecstatic Explorers&amp;body=Check out this site ' + current_url } className='icon'>
                          <img src='/env.svg' alt='Envelop' />
                        </a>
                        <a href='#' className='icon' onClick={ onHandlePrint }>
                          <img src='/print.svg' alt='Print' />
                        </a>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='other-blog-part'>
          <div className='container'>
            <div className='row'>
              <h5>You Might Also Like</h5>
              { blog.map((data) => (
                <div className='col-md-4'>
                  <Link to={ '/blog-details/' + data.id } className='text-decoration-none'>
                    <div className='blog-other'> 
                      <div className='blog-image'>
                        <img src={ data.file_path } alt='Blog' />
                      </div>
                      <div className='blog-content'>
                        <div className='sub-text'>{ data.text }</div>
                        <h5>{ data.title }</h5>
                        <div className='tag-text'>
                          <div className='pub'>Ecstatic Explorers</div>
                          <div className='line'>|</div>
                          <div className='text'>4 Min Read</div>
                        </div>
                      </div>
                    </div> 
                  </Link>  
                </div>
              ))}  
              <div className='view-part'><Link to='/blog' className='btn-view text-decoration-none'>View More</Link></div>
            </div> 
          </div>
        </section>
      </div>
    </div>
  );
}
export default BlogDetails;