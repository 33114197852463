import React, { useState } from 'react';
import { Link } from 'react-router-dom';


function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <header>
      <div className='logo-wrap'>
        <Link to='/home' className='logo'>
          <img src='/logo.png' alt='Logo' />
        </Link>
        <div className='hamburger' onClick={toggleMenu}>
          <div className={menuOpen ? 'bar bar1 open' : 'bar bar1'}></div>
          <div className={menuOpen ? 'bar bar2 open' : 'bar bar2'}></div>
          <div className={menuOpen ? 'bar bar3 open' : 'bar bar3'}></div>
        </div>
      </div>
      <div className={`menu-lists ${menuOpen ? 'open' : ''}`}>
        <Link to='/home' className='menuLink' onClick={() => setMenuOpen(false)}>
          <div className='icon'>
            <img src='/home.svg' alt='Logo' />
          </div>
          <div className='text'>Home</div>
        </Link>
        <Link to='/about-us' className='menuLink' onClick={() => setMenuOpen(false)}>
          <div className='icon'>
            <img src='/about-icon.svg' alt='Logo' />
          </div>
          <div className='text'>About Us</div>
        </Link>
        <Link to='/contact-us' className='menuLink' onClick={() => setMenuOpen(false)}>
          <div className='icon'>
            <img src='/contact-icon.svg' alt='Logo' />
          </div>
          <div className='text'>Contact Us</div>
        </Link>
        <Link to='/blog' className='menuLink' onClick={() => setMenuOpen(false)}>
          <div className='icon'>
            <img src='/blog-icon.svg' alt='Logo' />
          </div>
          <div className='text'>Blog</div>
        </Link>
      </div>
    </header>

  );
}

export default Header;
