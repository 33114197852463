import { React, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination'; 
import { Link, useParams } from 'react-router-dom'; 
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import axios from 'axios'; 
import { BASE_URL, IMG_BASE_URL } from '../Environment';
   
function LocationDetails() {
  const current_url = window.location.href; 
  const [package_price, setPackagePrice] = useState();  
  const [package_itinerary, setPackageItinerary] = useState();   
  const [package_itinerary_details, setPackageItineraryDetails] = useState();    
  const [package_image_1, setPackageImage1] = useState();
  const [package_image_2, setPackageImage2] = useState();
  const [package_image_3, setPackageImage3] = useState();
  const [package_image_4, setPackageImage4] = useState();
  const [package_image_5, setPackageImage5] = useState(); 
  const month_no = new Date().getMonth();   
  const [key, setKey] = useState('tour');
  const [selectedStar, setSelectedStar] = useState('standard');
  const [selectedMonth, setSelectedMonth] = useState(month_no + 1); 
  const handleChange = (e) => {     
    setSelectedStar(e.target.value); 
    setSelectedMonth(month_no + 1);  
    if(e.target.value == 'standard') {
      setPackagePrice(package_details.pakage_prices[month_no].price); 
      setPackageItinerary(package_details.pakage_itineraries[0].tour_itinerary);
      changeItineraryData(4); 
    } else if(e.target.value == 'deluxe') {
      setPackagePrice(package_details.pakage_prices[month_no + 12].price); 
      setPackageItinerary(package_details.pakage_itineraries[1].tour_itinerary);
      changeItineraryData(3);
    } else if(e.target.value == 'super-deluxe') {
      setPackagePrice(package_details.pakage_prices[month_no + 24].price); 
      setPackageItinerary(package_details.pakage_itineraries[2].tour_itinerary);
      changeItineraryData(2);
    } else if(e.target.value == 'premium') {
      setPackagePrice(package_details.pakage_prices[month_no + 36].price); 
      setPackageItinerary(package_details.pakage_itineraries[3].tour_itinerary);
      changeItineraryData(1); 
    }        
  }; 
  const changeItineraryData = (data) => {
    axios.get(`${BASE_URL}/pakage/itinerary/`+id+'/'+data, {
      headers: {   
        'Content-Type': 'application/json'
      }    
    }).then((res) => {   
      setPackageItineraryDetails(res.data.data);   
    }).catch((res) => {
      console.log(res); 
    }); 
  };    
  function onChangeData(event) {
    const data = Number(event.target.value) - 1;    
    if(selectedStar == 'standard') {
      setPackagePrice(package_details.pakage_prices[data].price); 
    } else if(selectedStar == 'deluxe') {
      setPackagePrice(package_details.pakage_prices[data + 12].price); 
    } else if(selectedStar == 'super-deluxe') {
      setPackagePrice(package_details.pakage_prices[data + 24].price); 
    } else if(selectedStar == 'premium') {
      setPackagePrice(package_details.pakage_prices[data + 36].price); 
    }       
  }      
  const { id } = useParams(); 
  const [isLoading, setIsLoading] = useState(false); 
  const [package_details, setPackageDetails] = useState([]);  
  const [related_package_1, setRelatedPackage1] = useState([]);  
  const [related_package_2, setRelatedPackage2] = useState([]);  
  useEffect(() => {  
    setIsLoading(true);
    axios.get(`${BASE_URL}/pakage/details/`+id, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {   
      setPackageDetails(res.data.data);
      setPackagePrice(res.data.data.pakage_prices[month_no].price);     
      setPackageItinerary(res.data.data.pakage_itineraries[0].tour_itinerary);    
      if(res.data.data.pakage_images[0]) {
        setPackageImage1(res.data.data.pakage_images[0].file_path);
      } 
      if(res.data.data.pakage_images[1]) {
        setPackageImage2(res.data.data.pakage_images[1].file_path); 
      }
      if(res.data.data.pakage_images[2]) {
        setPackageImage3(res.data.data.pakage_images[2].file_path);
      }
      if(res.data.data.pakage_images[3]) {
        setPackageImage4(res.data.data.pakage_images[3].file_path);
      }
      if(res.data.data.pakage_images[4]) {
        setPackageImage5(res.data.data.pakage_images[4].file_path);   
      }     
      setIsLoading(false);   
    }).catch((res) => {
      console.log(res); 
    });    
    axios.get(`${BASE_URL}/pakage/itinerary/`+id+'/'+4, {
      headers: {  
        'Content-Type': 'application/json'
      }    
    }).then((res) => {   
      setPackageItineraryDetails(res.data.data);   
    }).catch((res) => {
      console.log(res); 
    });
    axios.get(`${BASE_URL}/get-explore-by-states`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setRelatedPackage1(res.data.data);
    }).catch((res) => {
      console.log(res); 
    });   
    axios.get(`${BASE_URL}/get-explore-the-unexplored-states`, {
      headers: { 
        'Content-Type': 'application/json'
      }  
    }).then((res) => {
      setRelatedPackage2(res.data.data);
    }).catch((res) => {
      console.log(res); 
    });       
  }, []); 
  return (
    <div className='app'>
      <div className='main-content'>
        <section className='details-banner-section'>
          <div className='parent'>
            <div className='div1'>
              <div className='image-part'>
                <img src={ package_image_1 ? IMG_BASE_URL + package_image_1 : '/no-image.svg' } alt='Details-image' />
              </div>
            </div>
            <div className='div2'>
              <div className='image-part'>
                <img src={ package_image_2 ? IMG_BASE_URL + package_image_2 : '/no-image.svg' } alt='Details-image' />
              </div> 
            </div>
            <div className='div3'>
              <div className='image-part'>
                <img src={ package_image_3 ? IMG_BASE_URL + package_image_3 : '/no-image.svg' } alt='Details-image' />
              </div>
            </div>
            <div className='div4'>
              <div className='image-part'>
                <img src={ package_image_4 ? IMG_BASE_URL + package_image_4 : '/no-image.svg' } alt='Details-image' />
              </div>
            </div>
            <div className='div5'>
              <div className='image-part'>
                <img src={ package_image_5 ? IMG_BASE_URL + package_image_5 : '/no-image.svg' } alt='Details-image' />
              </div>   
              { package_details.pakage_images?.length > 5 ? 
                <div className='overlay-images'>
                  <div className='icon'>
                    <img src='/image-frame.svg' alt='Details-image' />
                  </div>
                  <div className='text'>View more images</div>
                </div>
              : null }
            </div>  
          </div>
          <div className='back-action'>
            <Link to='/packages'>Back to Packages<i className='fas fa-chevron-circle-left ms-2'></i></Link>
          </div> 
        </section>
        <div className='container pt-4'>
          <div className='row justify-content-center'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'><Link to='/home'><i className='fas fa-home'></i></Link></li>
                <span className='arrow'><i className='fas fa-chevron-right'></i></span>
                <li className='breadcrumb-item active'><Link to='/packages'>Packages</Link></li>
                <span className='arrow'><i className='fas fa-chevron-right'></i></span>
                <li className='breadcrumb-item ' aria-current='page'>Package Details</li>
              </ol>   
            </nav>
          </div> 
        </div>
        <section className='details-part-section'>
          <div className='container'>
            <div className='row'>
              <div className='inner-wrap'>
                <div className='left-side-content'>
                  { isLoading ? <p className='is-loading'>Loading...</p> : null } 
                  <h4>{ package_details.title }</h4> 
                  <div className='radio-buttons select-type'>
                    <label className='custom-radio'>
                      <input type='radio' value='standard' checked={selectedStar === 'standard'} onChange={handleChange} />
                      <span className='radio-label'>Standard</span>  
                    </label>

                    <label className='custom-radio'>
                      <input type='radio' value='deluxe' checked={selectedStar === 'deluxe'} onChange={handleChange} />
                      <span className='radio-label'>Deluxe</span>
                    </label>

                    <label className='custom-radio'>
                      <input type='radio' value='super-deluxe' checked={selectedStar === 'super-deluxe'} onChange={handleChange} />
                      <span className='radio-label'>Super Deluxe</span>
                    </label>

                    <label className='custom-radio'>
                      <input type='radio' value='premium' checked={selectedStar === 'premium'} onChange={handleChange} />
                      <span className='radio-label'>Premium</span> 
                    </label>
                  </div>
                  <p>{ package_details.short_description }</p>
                  <div className='lists-part'>
                    <div className='item'>
                      <div className='icon'>
                        <img src='/duration.svg' alt='Duration-image' />
                      </div>
                      <div className='text'>
                        <h5>Duration</h5>
                        <p>{ package_details.duration }</p>
                      </div> 
                    </div>
                    <div className='item'>
                      <div className='icon'>
                        <img src='/tour-type.svg' alt='Tour-Type' />
                      </div>
                      <div className='text'>
                        <h5>Tour Type</h5>
                        <p>{ package_details.category?.name }</p>
                      </div>  
                    </div>
                    <div className='item'>
                      <div className='icon'>
                        <img src='/language.svg' alt='Language' />
                      </div>
                      <div className='text'>
                        <h5>Language</h5>
                        <p>{ package_details.language?.name }</p>
                      </div>
                    </div>
                  </div> 
                  <div className='highlight-part'>
                    <h6>Highlights of the Tour</h6>
                    <div className='points'>
                      { package_details.pakage_features?.map((data) => (
                        <div className='p-item'>
                          <div className='icon'>
                            <img src='/check.svg' alt='Check-image' />
                          </div>
                          <div className='text'>
                            <p>{ data.name }</p>
                          </div>   
                        </div>
                      ))}  
                    </div> 
                  </div>
                  <div className='info-tab-part'>
                    <Tabs
                      id='controlled-tab-example'
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className='mb-3'
                    >
                      <Tab eventKey='tour' title='Tour Itinerary'>
                        <div className='tab-content-inner'>
                          <h4>Itinerary</h4>
                          <div dangerouslySetInnerHTML={{ __html: package_itinerary }}></div>
                          <Accordion defaultActiveKey='0'> 
                            { package_itinerary_details?.map((data) => ( 
                              <Accordion.Item eventKey={ data.id }> 
                                <Accordion.Header> 
                                  <div className='header-inner'>
                                    <div className='day'>Day { data.day_no }</div>
                                    <div className='wrap'>
                                      <h5>{ data.title }</h5>
                                      <div className='radio-buttons'>
                                        { data.check_in == 1 ?
                                          <label className='custom-radio'>
                                            <input type='radio' name='check' value='Check-in' />
                                            <span className='radio-label'>Check-in</span>
                                          </label>
                                        : null }
                                        { data.sight_seeing == 1 ?
                                          <label className='custom-radio'>
                                            <input type='radio' name='sight' value='sight' />
                                            <span className='radio-label'>Sight Seeing </span>
                                          </label>
                                        : null } 
                                      </div>
                                    </div>  
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p>{ data.text }</p>
                                  <div className='item-part'>
                                    <div className='icon'>
                                      <img src='/stay.svg' alt='Stay-image' />
                                    </div>
                                    <div className='text'>Stay At</div>
                                  </div>
                                  <h5>{ data.stay_at }</h5>
                                  <div className='location-img'>
                                    <div className='img-item'>
                                      <div className='img-part'>
                                        <img src={ IMG_BASE_URL + data.hotel_image_1 } alt='Stay-image' />
                                      </div>
                                      <div className='text'>{ data.hotel_image_1_text }</div>
                                    </div>
                                    <div className='img-item'>
                                      <div className='img-part'>
                                        <img src={ IMG_BASE_URL + data.hotel_image_2 } alt='Stay-image' />
                                      </div>
                                      <div className='text'>{ data.hotel_image_2_text }</div>
                                    </div>  
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </div>
                      </Tab>
                      <Tab eventKey='inclusion' title='Tour Inclusion'>
                        <div dangerouslySetInnerHTML={{ __html: package_details.tour_inclusion }}></div> 
                      </Tab>
                      <Tab eventKey='exclusion' title='Tour Exclusion'>
                        <div dangerouslySetInnerHTML={{ __html: package_details.tour_exclusion }}></div>
                      </Tab>
                      <Tab eventKey='booking' title='Booking Policy' >
                        <div dangerouslySetInnerHTML={{ __html: package_details.booking_policy }}></div>
                      </Tab>
                      <Tab eventKey='policy' title='Cancellation Policy' >
                        <div dangerouslySetInnerHTML={{ __html: package_details.cancellation_policy }}></div>
                      </Tab> 

                    </Tabs>
                  </div>
                </div>
                <div className='right-side-content'>
                  <div className='info-card'>
                    <h5 className='text'>Amazingly { package_details.state?.name } Tour Package</h5>
                    <div className='inner-content'>  
                      <div className='rating-part'>    
                        <div className='left-side'>
                          <h5>Rated 4.1/5 (based on 1505 reviews)</h5>
                          <div className='contact'>
                            <div className='icon'>
                              <img src='/call-p.svg' alt='Call-image' />
                            </div>
                            <div className='text'>Call Us for Details <strong>+91 00000 00000</strong> </div>
                          </div>
                        </div>
                        <div className='share'>
                          <a href={ 'whatsapp://send?text=' + current_url } className='wp-button text-decoration-none' target='_blank'>
                            <div className='img-part'> 
                              <img src='/wp.svg' alt='Wp-image' />
                            </div>
                            <div className='text'>Share</div>
                          </a>
                        </div>
                      </div>
                      <div className='star-part'>
                        <h6>Hotel Included in Package:</h6>
                        <div className='star-option'>
                          <div className='star-rating'>
                            <div className='star-rating'>
                              <label className='custom-radio-star'>
                                <input
                                  type='radio'
                                  value='premium'
                                  checked={selectedStar === 'premium'}
                                  onChange={handleChange}
                                />
                                <span className='radio-label'>5 Star</span>
                              </label>

                              <label className='custom-radio-star'>
                                <input
                                  type='radio'
                                  value='super-deluxe'
                                  checked={selectedStar === 'super-deluxe'}
                                  onChange={handleChange}
                                />
                                <span className='radio-label'>4 Star</span>
                              </label>

                              <label className='custom-radio-star'>
                                <input
                                  type='radio'
                                  value='deluxe'
                                  checked={selectedStar === 'deluxe'}
                                  onChange={handleChange}
                                />
                                <span className='radio-label'>3 Star</span>
                              </label>

                              <label className='custom-radio-star'>
                                <input
                                  type='radio' 
                                  value='standard'
                                  checked={selectedStar === 'standard'}
                                  onChange={handleChange}
                                /> 
                                <span className='radio-label'>2 Star</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='cities-part'>
                        <div className='cities'>
                          <span className='text'>Cities:</span>
                          { package_details.pakage_cities?.map((data) => (
                            <span className='city'>{ data.city.name } ({ data.duration }D)<i className='fas fa-arrow-right ms-2'></i></span>
                          ))}  
                        </div>
                      </div>  
                      <div className='feature-part'>
                        <div className='feature'>
                          <div className='icon'>
                            <img src='/hotel-icon.svg' />
                          </div>
                          <div className='text'>2 Stars</div>
                        </div>
                        <div className='feature'>
                          <div className='icon'>
                            <img src='/breakfast.svg' alt='Breakfast-image' />
                          </div>
                          <div className='text'>Breakfast</div>
                        </div>
                        <div className='feature'>
                          <div className='icon'>
                            <img src='/transfer.svg' alt='Transfer' />
                          </div>
                          <div className='text'>Transfers</div>
                        </div>
                        <div className='feature'>
                          <div className='icon'>
                            <img src='/tele.svg' alt='Tele-image' />
                          </div>
                          <div className='text'>Sightseeing</div>
                        </div>
                      </div>
                      <div className='price-part'>
                        <div className='price'>
                          <div className='sub-text'>Starting from:</div> 
                          <h5>₹{ package_price }/- <span className='cut-price'>₹42,687/-</span></h5>
                          <p>Per Person on twin sharing</p>
                        </div> 
                        <div className='select-part'>
                          <div className='sub-text'>Price For The Month</div>
                          <Form.Select aria-label='Default select example' onChange={ onChangeData }>  
                            <option value='1' selected={selectedMonth === 1}>Jan { new Date().getFullYear() }</option>
                            <option value='2' selected={selectedMonth === 2}>Feb { new Date().getFullYear() }</option>
                            <option value='3' selected={selectedMonth === 3}>Mar { new Date().getFullYear() }</option>
                            <option value='4' selected={selectedMonth === 4}>Apr { new Date().getFullYear() }</option>
                            <option value='5' selected={selectedMonth === 5}>May { new Date().getFullYear() }</option>
                            <option value='6' selected={selectedMonth === 6}>Jun { new Date().getFullYear() }</option>
                            <option value='7' selected={selectedMonth === 7}>Jul { new Date().getFullYear() }</option>
                            <option value='8' selected={selectedMonth === 8}>Aug { new Date().getFullYear() }</option>
                            <option value='9' selected={selectedMonth === 9}>Sep { new Date().getFullYear() }</option>
                            <option value='10' selected={selectedMonth === 10}>Oct { new Date().getFullYear() }</option>
                            <option value='11' selected={selectedMonth === 11}>Nov { new Date().getFullYear() }</option>
                            <option value='12' selected={selectedMonth === 12}>Dec { new Date().getFullYear() }</option>
                          </Form.Select> 
                        </div>   
                      </div>
                    </div>
                  </div>
                  <div className='contact-part'>
                    <a href='tel:+1234567890' className='contact-item text-decoration-none'>
                      <div className='icon'>
                        <img src='/call.svg' />
                      </div>
                      <div className='text'>Contact</div>
                    </a>
                    <a href='https://wa.me/+1234567890?text=Hello' className='contact-item text-decoration-none' target='_blank'>
                      <div className='icon'>  
                        <img src='/wp.svg' />
                      </div>
                      <div className='text'>Whatsapp</div>
                    </a>
                    <div className='contact-item'>
                      <div className='icon'>
                        <img src='/chat.svg' />
                      </div>
                      <div className='text'>Write Us</div>
                    </div>
                  </div>
                  <div className='related-part'>
                    <h5>Related Packages</h5>
                    <div className='part-lists'>
                      <div className='card'>
                        <Swiper
                          modules={[Pagination]}
                          spaceBetween={20}
                          slidesPerView={2.5}
                          pagination={{ clickable: true }}
                          loop={true}
                          breakpoints={{
                            320: {
                              slidesPerView: 1.5,
                            },
                            480: {
                              slidesPerView: 1.5,
                            },
                            640: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 1,
                            },
                            1024: {
                              slidesPerView: 1.5,
                            },
                            1366: {
                              slidesPerView: 2,
                            },
                            1600: {
                              slidesPerView: 2.5,
                            },
                          }}
                        >
                          { related_package_1.map((data) => (
                            <SwiperSlide> 
                              <div className='card-item'>
                                <Link to={ '/package/' + data.id } className='text-decoration-none'>
                                  <div className='card-image'>
                                    <img src={ data.file_path } alt='Image-state' />
                                  </div>
                                  <div className='card-text'>
                                    <h3>{ data.name }</h3>
                                    <p>Starting at ₹{ data.starting_price } <br /> Per person</p>
                                  </div>  
                                </Link>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                    <div className='part-lists mt-4'>
                      <div className='card'>
                        <Swiper
                          modules={[Pagination]}
                          spaceBetween={20}
                          slidesPerView={2.5}
                          pagination={{ clickable: true }}
                          loop={true}
                          breakpoints={{
                            320: {
                              slidesPerView: 1.5,
                            },
                            480: {
                              slidesPerView: 1.5,
                            },
                            640: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 1,
                            },
                            1024: {
                              slidesPerView: 1.5,
                            },
                            1366: {
                              slidesPerView: 2,
                            },
                            1600: {
                              slidesPerView: 2.5,
                            },
                          }}
                        >
                          { related_package_2.map((data) => (
                            <SwiperSlide>
                              <div className='card-item'>
                                <Link to={ '/package/' + data.id } className='text-decoration-none'>
                                  <div className='card-image'>
                                    <img src={ data.file_path } alt='Image-state' />
                                  </div>
                                  <div className='card-text'>
                                    <h3>{ data.name }</h3>
                                    <p>Starting at ₹{ data.starting_price } <br /> Per person</p>
                                  </div>  
                                </Link> 
                              </div>
                            </SwiperSlide> 
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='end-part'>
                <div className='end-img'>
                  <img src='/end.svg' alt='End' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='do-dont-section'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-6'>
                <div className='do-part'>
                  <div className='header-part'>
                    <div className='icon'>
                      <img src='/like.svg' alt='Like' />
                    </div>
                    <h2>Do's</h2>
                  </div>
                  <div className='lists-part'>
                    <div dangerouslySetInnerHTML={{ __html: package_details.do_s }}></div>
                  </div> 
                </div>
              </div>
              <div className='col-md-6'>
                <div className='donot-part'>
                  <div className='header-part'>
                    <div className='icon'>
                      <img src='/dislike.svg' alt='Dislike' />
                    </div>
                    <h2>Don'ts</h2>
                  </div>
                  <div className='lists-part'>
                    <div dangerouslySetInnerHTML={{ __html: package_details.don_ts }}></div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default LocationDetails;