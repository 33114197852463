import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Footer from './component/footer';
import Header from './component/header';
import Home from './Pages/Home';
import LocationLists from './Pages/LocationLists';
import LocationDetails from './Pages/LocationDetails';
import Blog from './Pages/Blog';
import BlogDetails from './Pages/BlogDetails';
import Contact from './Pages/Contact';
import Favicon from "react-favicon";
import { useState } from "react";
import About from './Pages/About';
import PrivacyPolicy from './Pages/PrivacyPolicy'; 
import TermCondition from './Pages/TermCondition'; 
import Package from './Pages/Package';

function App() {
  useEffect(() => {
    document.title = "Ecstatic Explorers";
  }, []);
  const [faviconUrl] = useState(
    "/logo.png"
  ); 

  return (
    <>
      <Favicon url={faviconUrl} />
      <div className="app">
        <Header />
        <div className='fixed-part'> 
          <a href='https://www.facebook.com/ecstaticexplorers' className='fb-part' target='_blank'>Facebook</a>
          <a href='https://www.instagram.com/ecstaticexplorers' className='in-part' target='_blank'>Instagram</a>
        </div> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/packages" element={<LocationLists />} />
          <Route path="/package/:id" element={<Package />} />
          <Route path="/package-details/:id" element={<LocationDetails />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details/:id" element={<BlogDetails />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermCondition />} />
        </Routes>    
        <Footer /> 
      </div>
    </>  
  );
}

export default App;
