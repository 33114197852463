import { React, useState, useEffect } from 'react'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import axios from 'axios'; 
import { BASE_URL } from '../Environment';
import { Link } from 'react-router-dom';
 
function Banner() {
  const [banner, setBanner] = useState([]);
  const [trending, setTrending] = useState([]);
  useEffect(() => { 
    axios.get(`${BASE_URL}/banners`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setBanner(res.data.data);
    }).catch((res) => {
      console.log(res); 
    });   
    axios.get(`${BASE_URL}/get-trending-states`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setTrending(res.data.data);
    }).catch((res) => {
      console.log(res); 
    });     
  }, []);   
  return (
    <section className='banner-carousel'>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        loop={true}
      >
        { banner.map((data) => (
          <SwiperSlide>
            <div className='slide-content'>
              <div className='banner-image'>
                <img src={ data.file_path } alt='Slide' />
              </div>
              <div className='banner-section'>
                <h1>{ data.title }</h1>
                <h2>{ data.sub_title }</h2> 
                <div className='location-lists'>
                  <div className='location-inner'>
                    <Swiper
                      modules={[Navigation, Pagination, Autoplay]}
                      spaceBetween={10}
                      slidesPerView={6}
                      navigation={{ clickable: true }}
                      loop={true}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                        },
                        480: {
                          slidesPerView: 2,
                        },
                        640: {
                          slidesPerView: 3,
                        },
                        768: {
                          slidesPerView: 4,
                        },
                        1024: {
                          slidesPerView: 4,
                        },
                        1366: {
                          slidesPerView: 6,
                        },
                      }}
                    >
                      { trending.map((data) => (
                        <SwiperSlide>
                          <Link to={ '/package/' + data.id } className='location-item text-white text-decoration-none'>
                            <div className='location-image'>  
                              <img src={ data.trending_image } alt='Leh Ladakh' />
                            </div>
                            <div className='text'>{ data.name }</div>
                            <div className='tag'>Trending <span>
                              <img src='/trend.svg' />
                            </span> 
                            </div> 
                          </Link> 
                        </SwiperSlide>
                      ))} 
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))} 
      </Swiper>
    </section>
  );
}

export default Banner;
