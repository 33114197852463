import { React, useState, useEffect } from 'react';
import 'swiper/css'; 
import 'swiper/css/navigation';
import 'swiper/css/pagination'; 
import { Link } from 'react-router-dom';
import Banner from '../component/banner';
import axios from 'axios'; 
import { BASE_URL } from '../Environment';
 
function Blog() {
  const [isLoading, setIsLoading] = useState(false); 
  const [blog, setBlog] = useState([]);
  const [category, setCategory] = useState([]);
  useEffect(() => { 
    setIsLoading(true);
    axios.get(`${BASE_URL}/blogs`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setBlog(res.data.data);
      setIsLoading(false); 
    }).catch((res) => {
      console.log(res); 
    });   
    axios.get(`${BASE_URL}/blog-category`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setCategory(res.data.data);
    }).catch((res) => {
      console.log(res); 
    });     
  }, []); 
  return (
    <div className='app'>
      <div className='main-content'>
        <Banner />
        <div className='container pt-4'>
          <div className='row justify-content-center'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'><Link to='/home'><i className='fas fa-home'></i></Link></li> 
                <span className='arrow'><i className='fas fa-chevron-right'></i></span> 
                <li className='breadcrumb-item ' aria-current='page'>Blog</li>
              </ol> 
            </nav>
          </div>
        </div> 
        <section className='latest-blog-section'>
          <div className='container'>
            <div className='row'>
              <h5>Latest Blogs</h5>
              <div className='col-md-12'>
                <div className='blog-inner'>
                  <div className='blog-post'>
                    { isLoading ? <p className='is-loading'>Loading...</p> : null }   
                    { blog.map((data) => (
                      <Link to={ '/blog-details/' + data.id } className='blog-item'>
                        <div className='image-part'>
                          <img src={ data.file_path } alt='blog image' />
                        </div> 
                        <div className='content-part'>
                          <div className='sub-tag'>
                            { data.blog_tags.map((data) => (
                              <span>{ data.tag.name } / </span>
                            ))}  
                          </div> 
                          <h5>{ data.title }</h5>
                          <p>{ data.text }</p>
                        </div>  
                      </Link>
                    ))}
                  </div>
                  <div className='blog-info-part'>
                    <div className='follow-us'>
                      <h6>Follow Us</h6>
                      <div className='follow-part'>
                        <a href='https://www.facebook.com/ecstaticexplorers' className='follow-item' target='_blank'>
                          <div className='wrap'>
                            <div className='icon'>
                              <img src='/fb-box.svg' alt='Fb' />
                            </div>
                            <div className='text'>
                              Facebook
                            </div>
                          </div>
                          <div className='text-part'>Like</div>
                        </a> 
                        <a href='https://www.instagram.com/ecstaticexplorers' className='follow-item' target='_blank'>
                          <div className='wrap'>
                            <div className='icon'>
                              <img src='/insta-box.svg' alt='Insta' />
                            </div>
                            <div className='text'>
                              Instagram
                            </div>
                          </div> 
                          <div className='text-part'>Follow</div>
                        </a>
                        <a href='https://www.youtube.com' className='follow-item' target='_blank'>
                          <div className='wrap'> 
                            <div className='icon'>
                              <img src='/youtube-box.svg' alt='Youtube' />
                            </div>
                            <div className='text'>
                              Youtube
                            </div>
                          </div>
                          <div className='text-part'>Subscribe</div>
                        </a>
                      </div>
                    </div>
                    <div className='categories'>
                      <h6>Categories</h6>
                      <div className='category-lists'>
                        { category.map((data) => (
                          <div className='category-item'>
                            <div className='img-part'>
                              <img src={ data.file_path } alt='Card-image' />
                            </div>
                            <div className='content-part'>
                              <h6>{ data.name }</h6>
                              <p>{ data.blog_count } Articles</p>
                            </div> 
                          </div>
                        ))} 
                      </div>
                    </div>
                  </div>
                </div>
                <div className='view-part'>
                  <button type='button' className='view-btn'>View More</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Blog;