import { React, useState } from 'react';
import { Link } from 'react-router-dom'; 
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination'; 
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Banner from '../component/banner';
import axios from 'axios'; 
import { BASE_URL } from '../Environment';
import { ToastContainer, toast } from 'react-toastify';   
import 'react-toastify/dist/ReactToastify.css'; 
     
function Contact() {
  const [name, setName] = useState(''); 
  const [phone, setPhone] = useState(''); 
  const [email, setEmail] = useState(''); 
  const [message, setMessage] = useState(''); 
  const [error, setError] = useState('');  
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post(`${BASE_URL}/contact-us`, { name, phone, email, message }, {
      headers: {
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      toast.success(res.data.message, { autoClose: 3000 });
      e.target.reset();  
    }).catch((res) => {
      setError(res.response.data); 
    })   
  }  
  return (
    <div className='app'>
      <ToastContainer />  
      <div className='main-content'>
        <Banner />
        <div className='container pt-4'>
          <div className='row justify-content-center'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'><Link to='/home'><i className='fas fa-home'></i></Link></li> 
                <span className='arrow'><i className='fas fa-chevron-right'></i></span> 
                <li className='breadcrumb-item ' aria-current='page'>Contact Us</li>
              </ol> 
            </nav>
          </div>
        </div> 
        <section className='contact-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='contact-info'>
                  <div className='contact-item'>
                    <div className='text'>Phone</div>
                    <div className='inner-content'>
                      <div className='icon'>
                        <img src='/call-c.svg' alt='Call' />
                      </div>
                      <div className='text-part'>
                        <p>+91 89108 45933</p>
                        <p>+91 98361 55825</p>
                      </div>
                    </div>
                  </div>
                  <div className='contact-item'>
                    <div className='text'>Email </div>
                    <div className='inner-content'>
                      <div className='icon'>
                        <img src='/env-c.svg' />
                      </div>
                      <div className='text-part'>
                        <p>explore@ecstaticexplorers.com</p>
                      </div>
                    </div>
                  </div>
                  <div className='contact-item'>
                    <div className='text'>Location </div>
                    <div className='inner-content'>
                      <div className='icon'>
                        <img src='/location-c.svg' />
                      </div>
                      <div className='text-part'>
                        <p>Premises no. D-206, Mondal Ganthi, Arjunpur, Kaikhali, North Dumdum, West Bengal, India. Kolkata -700052</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='wp-share-part'>
                  <h5>We'd love to hear from you!</h5>
                  <p>Have a question about our services? Want to see if your particular vacation is one we can help with? We'd love to hear from you!</p>
                  <div className='wp-part'>
                    <div className='icon'>
                      <img src='/wp.svg' alt='Wp' />
                    </div>
                    <div className='text'>Message us on WhatsApp</div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-part'>
                  <h5>Reach Us Anytime</h5>
                  <Form onSubmit={ handleSubmit }> 
                    <Form.Group className='mb-3' controlId='formBasicName'>
                      <Form.Label>Name *</Form.Label>
                      <Form.Control type='text' onChange={ e => setName(e.target.value) } placeholder='Enter your name' />
                      { error ? <small className='input-required'>{ error.data.name[0] }</small> : null }
                    </Form.Group>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Form.Group className='mb-3' controlId='formBasicPhone'>
                          <Form.Label>Phone No. *</Form.Label>
                          <Form.Control type='number' onChange={ e => setPhone(e.target.value) } placeholder='Enter your phone no.' />
                          { error ? <small className='input-required'>{ error.data.phone[0] }</small> : null }
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group className='mb-3' controlId='formBasicEmail'>
                          <Form.Label>Email ID *</Form.Label>
                          <Form.Control type='email' onChange={ e => setEmail(e.target.value) } placeholder='Enter your email id' />
                          { error ? <small className='input-required'>{ error.data.email[0] }</small> : null } 
                        </Form.Group> 
                      </div>
                    </div> 
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label>Write Your Massage *</Form.Label>
                      <Form.Control as='textarea' rows={3} onChange={ e => setMessage(e.target.value) } placeholder='Write your message' />
                      { error ? <small className='input-required'>{ error.data.message[0] }</small> : null }
                    </Form.Group>
                    <Button variant='primary' type='submit' className='btn-submit'>
                      Submit Now
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='map-section'>
          <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117925.21689836812!2d88.26495174703659!3d22.535564935323666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1727841755068!5m2!1sen!2sin' width='100%' height='450'  allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade'></iframe>
        </section>
      </div >
    </div >
  )
};
export default Contact;