import { React, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Banner from '../component/banner';
import { Link } from 'react-router-dom';
import axios from 'axios'; 
import { BASE_URL } from '../Environment';
 
function Home() {
  const [explore, setExplore] = useState([]); 
  const [selling, setSelling] = useState([]); 
  const [unexplore, setUnexplore] = useState([]); 
  useEffect(() => {   
    axios.get(`${BASE_URL}/get-explore-by-states`, {
      headers: { 
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setExplore(res.data.data);
    }).catch((res) => {
      console.log(res); 
    }); 
    axios.get(`${BASE_URL}/top-selling-pakages`, {
      headers: { 
        'Content-Type': 'application/json'
      }  
    }).then((res) => {
      setSelling(res.data.data);
    }).catch((res) => {
      console.log(res); 
    });  
    axios.get(`${BASE_URL}/get-explore-the-unexplored-states`, {
      headers: { 
        'Content-Type': 'application/json'
      }  
    }).then((res) => {
      setUnexplore(res.data.data);
    }).catch((res) => {
      console.log(res); 
    });      
  }, []);  
  return (
    <div className='app'>
      <div className='main-content'>
        <Banner />

        <section className='state-section'>
          <div className='rating-card'>
            <div className='logo-part'>
              <img src='/google.svg' alt='Logo' />
            </div>
            <div className='rating'>
              <i className='fas fa-star'></i>
              <i className='fas fa-star'></i>
              <i className='fas fa-star'></i>
              <i className='fas fa-star'></i>
              <i className='fas fa-star'></i>
            </div>
            <div className='text-part'>
              <strong>5.0</strong> rating from <strong>542</strong> reviews
            </div>
            <div className='users'>
              <div className='img-part'>
                <img src='/image.png' alt='User' />
              </div>
              <div className='img-part'>
                <img src='/image-1.png' alt='User' />
              </div>
              <div className='img-part'>
                <img src='/image-2.png' alt='User' />
              </div>
              <div className='img-part'>
                <div className='text'>+499</div>
              </div>
            </div>
            <button type='button' className='btn-review'>Leave Review</button>
          </div>
          <div className='container'>
            <div className='row'>
              <h2 className='section-title'>Explore By States</h2>
              <div className='col-md-12 state-inner'>
                <div className='card'>
                  <Swiper
                    modules={[Navigation]}
                    spaceBetween={20}
                    slidesPerView={7}
                    navigation={{ clickable: true }}
                    loop={true}
                    breakpoints={{
                      320: {
                        slidesPerView: 2,
                      },
                      480: {
                        slidesPerView: 2,
                      },
                      640: {
                        slidesPerView: 4,
                      },
                      768: {
                        slidesPerView: 5,
                      },
                      1024: {
                        slidesPerView: 5,
                      },
                      1366: {
                        slidesPerView: 6,
                      },
                      1600: {
                        slidesPerView: 7,
                      },
                    }}
                  >
                    { explore.map((data) => (
                      <SwiperSlide>
                        <div className='card-item'>
                          <Link to={ '/package/' + data.id } className='text-decoration-none'>
                            <div className='card-image'>
                              <img src={ data.file_path } alt='Image-state' />
                            </div>
                            <div className='card-text'>
                              <h3>{ data.name }</h3>
                              <p>Starting at ₹{ data.starting_price } <br /> Per person</p>
                            </div>  
                          </Link>
                        </div>
                      </SwiperSlide>
                    ))}  
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='popular-section'>
          <div className='container'>
            <div className='row'>
              <h2 className='section-title'>Most Popular Packages</h2>
              <div className='col-md-12 state-inner'>
                <div className='sub-text-part'>
                  <h3>Jackpot Deals on Top Selling Packages</h3>
                  <p>Save extra with our exclusive deals!</p>
                </div>
                <div className='card'>
                  <Swiper
                    modules={[Navigation]}
                    spaceBetween={20}
                    slidesPerView={7}
                    navigation={{ clickable: true }}
                    loop={true}
                    breakpoints={{
                      320: {
                        slidesPerView: 2,
                      },
                      480: {
                        slidesPerView: 2,
                      },
                      640: {
                        slidesPerView: 4,
                      },
                      768: {
                        slidesPerView: 5,
                      },
                      1024: {
                        slidesPerView: 5,
                      },
                      1366: {
                        slidesPerView: 6,
                      },
                      1600: {
                        slidesPerView: 7,
                      },
                    }}
                  >
                    { selling.map((data) => (
                      <SwiperSlide>
                        <div className='card-item'>
                          <Link to={ '/package-details/' + data.id }>
                            <div className='card-image'>
                              <img src={ data.pakage_file_path } alt='Image-state' />
                            </div>
                            <div className='card-text'>
                              <h3>{ data.title }</h3>  
                            </div> 
                          </Link>  
                        </div>    
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='holiday-section'>
          <div className='container'>
            <div className='row holiday-inner'>
              <div className='col-md-3'>
                <h6>Explore</h6>
                <h2>Holidays <br /> <span>by Theme</span></h2>
                <p>Pick from our specially curated packages</p>
              </div>
              <div className='col-md-9'>
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={20}
                  slidesPerView={3}
                  navigation={{ clickable: true }}
                  loop={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    480: {
                      slidesPerView: 1,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 2,
                    },
                    1366: {
                      slidesPerView: 2,
                    },
                    1600: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {/* Slide 1 */}
                  <SwiperSlide>
                    <div className='card-item'>
                      <div className='card-image'>
                        <img src='/image-explore.jpg' alt='Image-state' />
                      </div>
                      <div className='card-text'>
                        <h3>Luxe Holidays</h3>
                        <div className='tag-lists'>
                          <span className='tag'>Beach</span>
                          <span className='tag'>Cultural</span>
                          <span className='tag'>Island</span>
                          <span className='tag'>Hill</span>
                        </div>
                        <Link to='/packages' className='link-text'>View More</Link>
                      </div> 
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='card-item'>
                      <div className='card-image'>
                        <img src='/image-explore-1.jpg' alt='Image-state' />
                      </div>
                      <div className='card-text'>
                        <h3>Next-Gen Adventures</h3>
                        <div className='tag-lists'>
                          <span className='tag'>Adventure Awaits</span>
                          <span className='tag'>Party Paths</span>
                          <span className='tag'>Beach Vibes</span>
                        </div>
                        <Link to='/packages' className='link-text'>View More</Link>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='card-item'>
                      <div className='card-image'>
                        <img src='/image-explore-2.jpg' alt='Image-state' />
                      </div>
                      <div className='card-text'>
                        <h3>Honeymoon</h3>
                        <div className='tag-lists'>
                          <span className='tag'>Beaches</span>
                          <span className='tag'>Hill Vacays</span>
                          <span className='tag'>Adventure</span>
                          <span className='tag'>City Escapes</span>
                        </div>
                        <Link to='/packages' className='link-text'>View More</Link>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='card-item'>
                      <div className='card-image'>
                        <img src='/image-explore.jpg' alt='Image-state' />
                      </div>
                      <div className='card-text'>
                        <h3>Honeymoon</h3>
                        <div className='tag-lists'>
                          <span className='tag'>Beaches</span>
                          <span className='tag'>Hill Vacays</span>
                          <span className='tag'>Adventure</span>
                          <span className='tag'>City Escapes</span>
                        </div>
                        <Link to='/packages' className='link-text'>View More</Link>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='card-item'>
                      <div className='card-image'>
                        <img src='/image-explore.jpg' alt='Image-state' />
                      </div>
                      <div className='card-text'>
                        <h3>Honeymoon</h3>
                        <div className='tag-lists'>
                          <span className='tag'>Beaches</span>
                          <span className='tag'>Hill Vacays</span>
                          <span className='tag'>Adventure</span>
                          <span className='tag'>City Escapes</span>
                        </div>
                        <Link to='/packages' className='link-text'>View More</Link>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='card-item'>
                      <div className='card-image'>
                        <img src='/image-explore.jpg' alt='Image-state' />
                      </div>
                      <div className='card-text'>
                        <h3>Honeymoon</h3>
                        <div className='tag-lists'>
                          <span className='tag'>Beaches</span>
                          <span className='tag'>Hill Vacays</span>
                          <span className='tag'>Adventure</span>
                          <span className='tag'>City Escapes</span>
                        </div>
                        <Link to='/packages' className='link-text'>View More</Link>
                      </div>  
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section className='explore-section'>
          <div className='container'>
            <div className='row'>
              <h2 className='section-title'>Explore the Unexplored</h2>
              <div className='col-md-12 state-inner'>
                <div className='card'>
                  <Swiper
                    modules={[Navigation]}
                    spaceBetween={20}
                    slidesPerView={7}
                    navigation={{ clickable: true }}
                    loop={true}
                    breakpoints={{
                      320: {
                        slidesPerView: 2,
                      },
                      480: {
                        slidesPerView: 2,
                      },
                      640: {
                        slidesPerView: 4,
                      },
                      768: {
                        slidesPerView: 5,
                      },
                      1024: {
                        slidesPerView: 5,
                      },
                      1366: {
                        slidesPerView: 6,
                      },
                      1600: {
                        slidesPerView: 7,
                      },
                    }}
                  >
                    { unexplore.map((data) => (
                      <SwiperSlide>
                        <div className='card-item'>
                          <Link to={ '/package/' + data.id } className='text-decoration-none'>
                            <div className='card-image'>
                              <img src={ data.file_path } alt='Image-state' />
                            </div>
                            <div className='card-text'>
                              <h3>{ data.name }</h3>
                              <p>Starting at ₹{ data.starting_price } <br /> Per person</p>
                            </div> 
                          </Link> 
                        </div>
                      </SwiperSlide>
                    ))} 
                  </Swiper>
                </div>
              </div>  
            </div>
          </div>
        </section>

        <section className='rating-section'>
          <div className='container'>
            <div className='row'>
              <h2 className='section-title'>Rating & Reviews</h2>
              <div className='rating-lists-part'>
                <div className='rating-item-col'>
                  <div className='rating-item'>
                    <div className='author'>
                      <div className='img-part'>
                        <img src='/image.png' />
                      </div>
                      <div className='data-part'>
                        <div className='name-part'>Gopal Mondal</div>
                        <div className='day'>3 days ago</div>
                        <div className='rating'>
                          <i className='fas fa-star'></i>
                          <i className='fas fa-star'></i>
                          <i className='fas fa-star'></i>
                          <i className='fas fa-star'></i>
                          <i className='fas fa-star'></i>
                        </div>
                      </div>
                    </div>
                    <div className='des'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
                <div className='rating-item-col'>
                  <div className='rating-lists'>
                    <div className='rating-item'>
                      <div className='author'>
                        <div className='img-part'>
                          <img src='/image.png' />
                        </div>
                        <div className='data-part'>
                          <div className='name-part'>Gopal Mondal</div>
                          <div className='day'>3 days ago</div>
                          <div className='rating'>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                          </div>
                        </div>
                      </div>
                      <div className='des'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </div>
                    </div>
                    <div className='rating-item'>
                      <div className='author'>
                        <div className='img-part'>
                          <img src='/image.png' />
                        </div>
                        <div className='data-part'>
                          <div className='name-part'>Gopal Mondal</div>
                          <div className='day'>3 days ago</div>
                          <div className='rating'>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                          </div>
                        </div>
                      </div>
                      <div className='des'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </div>
                    </div>
                  </div>
                </div>
                <div className='rating-item-col middle'>
                  <div className='rating-item'>
                    <div className='rating-card'>
                      <div className='logo-part'>
                        <img src='/google.svg' alt='Logo' />
                      </div>
                      <div className='rating'>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                        <i className='fas fa-star'></i>
                      </div>
                      <div className='text-part'>
                        <strong>5.0</strong> rating from <strong>542</strong> reviews
                      </div>
                      <div className='users'>
                        <div className='img-part'>
                          <img src='/image.png' alt='User' />
                        </div>
                        <div className='img-part'>
                          <img src='/image-1.png' alt='User' />
                        </div>
                        <div className='img-part'>
                          <img src='/image-2.png' alt='User' />
                        </div>
                        <div className='img-part'>
                          <div className='text'>+499</div>
                        </div>
                      </div>
                      <button type='button' className='btn-review'>Leave A Review</button>
                    </div>
                  </div>
                </div>
                <div className='rating-item-col'>
                  <div className='rating-lists'>
                    <div className='rating-item'>
                      <div className='author'>
                        <div className='img-part'>
                          <img src='/image.png' />
                        </div>
                        <div className='data-part'>
                          <div className='name-part'>Gopal Mondal</div>
                          <div className='day'>3 days ago</div>
                          <div className='rating'>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                          </div>
                        </div>
                      </div>
                      <div className='des'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </div>
                    </div>
                    <div className='rating-item'>
                      <div className='author'>
                        <div className='img-part'>
                          <img src='/image.png' />
                        </div>
                        <div className='data-part'>
                          <div className='name-part'>Gopal Mondal</div>
                          <div className='day'>3 days ago</div>
                          <div className='rating'>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                            <i className='fas fa-star'></i>
                          </div>
                        </div>
                      </div>
                      <div className='des'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </div>
                    </div>
                  </div>
                </div>
                <div className='rating-item-col'>
                  <div className='rating-item'>
                    <div className='author'>
                      <div className='img-part'>
                        <img src='/image.png' />
                      </div>
                      <div className='data-part'>
                        <div className='name-part'>Gopal Mondal</div>
                        <div className='day'>3 days ago</div>
                        <div className='rating'>
                          <i className='fas fa-star'></i>
                          <i className='fas fa-star'></i>
                          <i className='fas fa-star'></i>
                          <i className='fas fa-star'></i>
                          <i className='fas fa-star'></i>
                        </div>
                      </div>
                    </div>
                    <div className='des'>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='intagram-section'>
          <div className='container'>
            <div className='row'>
              <h2 className='section-title'>Live Instagram Feed</h2>
              <div className='col-md-12'>
                <div className='parent'>
                  <div className='div1'>
                    <div className='img-part'>
                      <img src='/instagram.jpg' />
                    </div>
                  </div>
                  <div className='div2'>
                    <div className='img-part'>
                      <img src='/instagram-4.jpg' />
                    </div>
                  </div>
                  <div className='div3'>
                    <div className='img-part'>
                      <img src='/instagram-3.jpg' />
                    </div>
                  </div>
                  <div className='div4'>
                    <div className='img-part'>
                      <img src='/instagram-5.jpg' />
                    </div>
                  </div>
                  <div className='div5'>
                    <div className='img-part'>
                      <img src='/instagram-7.jpg' />
                    </div>
                  </div>
                  <div className='div6'>
                    <div className='img-part'>
                      <img src='/instagram-1.jpg' />
                    </div>
                  </div>
                  <div className='div7'>
                    <div className='img-part'>
                      <img src='/instagram-2.jpg' />
                    </div>
                  </div>
                  <div className='div8'>
                    <div className='img-part'>
                      <img src='/instagram-6.jpg' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>
    </div>
  );
}

export default Home;
