import { React, useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination'; 
import Banner from '../component/banner';
import axios from 'axios'; 
import { BASE_URL } from '../Environment';
 
function PrivacyPolicy() { 
  const [isLoading, setIsLoading] = useState(false);
  const [privacy_policy, setPrivacyPolicy] = useState([]);
  useEffect(() => { 
    setIsLoading(true);
    axios.get(`${BASE_URL}/privacy-policy`, {
      headers: {  
        'Content-Type': 'application/json'
      } 
    }).then((res) => {
      setPrivacyPolicy(res.data.data);
      setIsLoading(false); 
    }).catch((res) => {
      console.log(res); 
    });       
  }, []); 
  return (
    <div className='app'>
      <div className='main-content'>
        <Banner />
        <div className='container pt-4'>
          <div className='row justify-content-center'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb justify-content-center'>
                <li className='breadcrumb-item'><Link to='/home'><i className='fas fa-home'></i></Link></li> 
                <span className='arrow'><i className='fas fa-chevron-right'></i></span> 
                <li className='breadcrumb-item ' aria-current='page'>Privacy Policy</li>
              </ol>  
            </nav>
          </div>
        </div> 
        <section className='common-section'>
          <div className='container'>
            <div className='row'>
              <h6 className='section-title'>Privacy Policy</h6>
              { isLoading ? <p className='is-loading'>Loading...</p> : null }  
              <p dangerouslySetInnerHTML={{ __html: privacy_policy.description }}></p>
            </div>  
          </div> 
        </section>
      </div>
    </div>
  )
};
export default PrivacyPolicy;   